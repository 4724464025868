import React, { useState } from "react";
import Pic1 from "../../assets/images/raster/pages/courses-page/InfoSection/10_hot_niches.jpg";
import Pic2 from "../../assets/images/raster/pages/courses-page/InfoSection/how_to_start.jpg";
import Pic3 from "../../assets/images/raster/pages/courses-page/InfoSection/why_selling.jpg";
import TrackedLink from "../Analytics/TrackedLink";

const InfoSection = () => {
  const [isFull1, setIsFull1] = useState(false);
  const [isFull2, setIsFull2] = useState(false);
  const [isFull3, setIsFull3] = useState(false);

  return (
    <div className="container PgCP-list">
      <div className="row">
        <div className="col-lg-4 col-12">
          <img
            className="PgCP-img"
            src={Pic1}
            alt=""
            width="290"
            height="194.95"
            loading="lazy"
          />
        </div>
        <div className="col-lg-8 col-12">
          <h3>6 hot niches from the 2nd quarter of 2020</h3>
          <h4>English</h4>
          {isFull1 ? (
            <div className="PgCP-details">
              <p>
                If you’re really struggling to get a hold of the right kinds of
                products and niches on Amazon, this course is designed for you.
                Sometimes, even with the relevant sales information, you might
                have too many product ideas to choose from. We’re simplifying
                the research process by revealing the 6 best-performing niches
                from the 2nd quarter of 2020. No more guesswork or agonizing
                decisions to make. We’ve done the heavy lifting for you.
              </p>
              <p>
                Get started with these niche ideas before the competition{" "}
                <button type="button" onClick={() => setIsFull1(false)}>
                  Show less
                </button>
              </p>
            </div>
          ) : (
            <p>
              If you’re really struggling to get a hold of the right kinds of
              products and niches on Amazon, this course is designed for you.{" "}
              <button type="button" onClick={() => setIsFull1(true)}>
                Show more
              </button>
            </p>
          )}
          <h5>FREE</h5>
          <TrackedLink
            category="CoursesPageLP"
            action="goto"
            path="/app/#/course"
            class="button btn"
            target
          >
            GO TO COURSES PAGE
          </TrackedLink>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-12">
          <img
            className="PgCP-img"
            src={Pic2}
            alt=""
            width="290"
            height="194.95"
            loading="lazy"
          />
        </div>
        <div className="col-lg-8 col-12">
          <h3>How to start selling on Amazon: AMZScout beginners course</h3>
          <h4>English</h4>
          {isFull2 ? (
            <div className="PgCP-details">
              <p>
                Those first few stages of any process are always the most
                difficult, even when it comes to Amazon selling. You have plenty
                of questions about identifying profitable products and niches,
                setting prices, and sourcing supplies. This course will guide
                you through all of it, leaving no stone unturned. By covering
                all of the basics, you’ll be able to set up a sound strategy to
                start finding and selling products on Amazon. You’ll learn:
              </p>
              <ol>
                <li>How to get started</li>
                <li>How to find a product to sell</li>
                <li>
                  How to bring that product to Amazon and start selling it{" "}
                  <button type="button" onClick={() => setIsFull2(false)}>
                    Show less
                  </button>
                </li>
              </ol>
            </div>
          ) : (
            <p>
              Those first few stages of any process are always the most
              difficult, even when it comes to Amazon selling. You have plenty
              of questions about identifying profitable products and niches,
              setting prices, and sourcing supplies. This course will guide you
              through...{" "}
              <button type="button" onClick={() => setIsFull2(true)}>
                Show more
              </button>
            </p>
          )}
          <TrackedLink
            category="CoursesPageLP"
            action="goto"
            path="/app/#/course"
            class="button btn"
            target
          >
            GO TO COURSES PAGE
          </TrackedLink>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-12">
          <img
            className="course-img"
            src={Pic3}
            alt=""
            width="290"
            height="194.95"
            loading="lazy"
          />
        </div>
        <div className="col-lg-8 col-12">
          <h3>Why selling on Amazon is right for you</h3>
          <h4>English</h4>
          {isFull3 ? (
            <div className="PgCP-details">
              <p>
                You might be thinking about starting an Amazon business, but you
                still have doubts and reservations. You’re worried about the
                financial risks you’ll have to take, along with the countless
                hours you’ll be putting in. You want to ensure that you can
                replace, or even surpass the income you make from your day job.
              </p>
              <p>
                We’re here to tell you that it’s definitely possible. Using
                smart, cost-effective strategies, this course will explain how
                selling on Amazon can be a path for anyone.{" "}
                <button type="button" onClick={() => setIsFull3(false)}>
                  Show less
                </button>
              </p>
            </div>
          ) : (
            <p>
              You might be thinking about starting an Amazon business, but you
              still have doubts and reservations. You’re worried about the
              financial risks you’ll have to take, along with...{" "}
              <button type="button" onClick={() => setIsFull3(true)}>
                Show more
              </button>
            </p>
          )}
          <h5>FREE</h5>
          <TrackedLink
            category="CoursesPageLP"
            action="goto"
            path="/app/#/course"
            class="button btn"
            target
          >
            GO TO COURSES PAGE
          </TrackedLink>
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
