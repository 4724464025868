import React from "react";
import "../reverse-asin-lookup/HomeIntro/index.scss";
import IntroPic from "../../assets/images/raster/pages/courses-page/HomeIntro/course-items.png";
import webpIntroPic from "../../assets/images/raster/pages/courses-page/HomeIntro/course-items.webp";

const Intro = () => {
  return (
    <section className="PgCP-intro" id="home">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-7 my-auto">
            <h1>
              Learn How To Master Selling <br /> On Amazon With These Courses
            </h1>
          </div>
          <div className="col-12 col-lg-5 mx-auto ml-lg-auto">
            <picture>
              <source srcSet={webpIntroPic} type="image/webp" />
              <img
                src={IntroPic}
                alt="PgCP-items"
                width="232.5"
                height="98.92"
              />
            </picture>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Intro;
