import React from "react";

import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import OgImg from "../assets/images/raster/pages/courses-page/HomeIntro/course-items.png";
import Intro from "../components/courses-page/Intro";
import InfoSection from "../components/courses-page/InfoSection";
import "../components/courses-page/index.scss";

const PgCP = () => {
  return (
    <Layout bgHeader="transparent" isAbsoluteHeader>
      <Seo
        title="Master Selling On Amazon [2019] | AMZScout"
        description="Learn selling on Amazon like a pro! Topics include: locating profitable items, finding a supplier and what selling strategies work in 2019"
        page="courses-page"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <Intro />
      <InfoSection />
    </Layout>
  );
};

export default PgCP;
